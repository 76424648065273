import React from "react"
import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Apartments from "../../components/Apartments"

import "../styles.scss"

const HausPage = () => {
  return (
    <Layout>
      <SEO title="Unser Haus" />
      <section className="section">
        <div className="container">
          <nav className="breadcrumb" aria-label="breadcrumbs">
            <ul>
              <li>
                <Link to="/">Start</Link>
              </li>
              <li className="is-active">
                <Link to="/apartments/" aria-current="page">
                  Apartments
                </Link>
              </li>
            </ul>
          </nav>
        </div>
        <br />
      </section>
      <Apartments />
    </Layout>
  )
}

export default HausPage
