import React from "react"

/*
|--------------------------------------------------------------------------
| Apartments
|--------------------------------------------------------------------------
*/

import Apartment1 from "./images/apartment1"
import Apartment2 from "./images/apartment2"
import Apartment3 from "./images/apartment3"

const Apartments = () => (
  <section
    style={{
      backgroundColor: "#fafafa",
      paddingTop: "0.75rem",
    }}
  >
    <div className="has-text-centered">
      <br />
      <h3 className="title is-3">Die Apartments</h3>
      <h5 className="subtitle is-5">
        Wir bieten Ihnen drei Apartments für 2 bis 6 Personen
        <br />
        <br />
      </h5>
    </div>
    <div className="slider container">
      <div className="card">
        <Apartment1 />
        <div className="card-content">
          <div className="title is-5">Apartment 1</div>
          60m2 großes Apartment mit Terrasse für bis zu 5 Personen, 2
          Schlafzimmer mit je Dusche/WC, Wohnraum mit Couch...
        </div>
      </div>
      <div className="card">
        <Apartment2 />
        <div className="card-content">xxx</div>
      </div>
      <div className="card">
        <Apartment3 />
        <div className="card-content">xxx</div>
      </div>
    </div>
  </section>
)

export default Apartments
